<template>
  <!-- 整体背景 -->
  <div class="login-wrap">
    <ul class="ulBox">
      <!--color彩灯的个数以及背景颜色
        自定义属性 itemColor:每个彩灯的背景颜色，
        自定义属性 fromColor:每个彩灯的渐变颜色-->
      <li
        v-for="(item, index) in color.length"
        :key="item"
        :style="
          '--fromColor:' +
            color[color.length - 1 - index] +
            ';--itemColor:' +
            color[index] +
            ';'
        "
      ></li>
    </ul>
    <!--输入框-->
    <div class="form-wrapper">
      <div class="title">2025年线上抽奖系统</div>
      <el-input
        class="input"
        v-model="account"
        placeholder="请输入账号"
      ></el-input>
      <el-input
        class="input"
        v-model="password"
        placeholder="请输入密码"
      ></el-input>
      <div class="btn btn-primary btn-ghost" @click="onLogin">登陆</div>
    </div>
  </div>
</template>
<script>
import { login } from "@/api/main.js";

export default {
  name: "login",
  data() {
    return {
      account: "",
      password: "",
      color: [
        "#E6A23C",
        "#f3cb85",
        "#E65D6E",
        "greenyellow",
        "#E6A23C",
        "#f3cb85",
        "#E65D6E",
        "greenyellow",
        "#E6A23C",
        "#f3cb85",
        "#E65D6E",
        "greenyellow",
        "#E6A23C",
        "#f3cb85",
        "#E65D6E",
        "greenyellow",
        "#E6A23C",
        "#f3cb85",
        "#E65D6E",
        "greenyellow",
        "#E6A23C",
        "#f3cb85",
        "#E65D6E",
        "greenyellow",
        "#E6A23C",
        "#f3cb85",
        "#E65D6E",
        "greenyellow",
        "#E6A23C",
        "#f3cb85",
        "#E65D6E",
        "greenyellow",
        "#E6A23C",
      ],
    };
  },
  methods: {
    async onLogin() {
      if (!this.account) return this.$message.error("请输入账号");
      if (!this.password) return this.$message.error("请输入密码");
      let req = {
        act: this.account,
        pwd: this.password,
        code: "147258",
      };
      try {
        const res = await login(req);
        if (res.code == 200) {
          localStorage.setItem("token", res.msg.token);
          this.$router.push("/");
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error("登陆失败");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.login-wrap {
  height: 100%;
  font-family: JetBrains Mono Medium;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("./../../assets/loginBg.webp") no-repeat;
  background-size: 100% 100%;
  .form-wrapper {
    // width: 350px;
    margin: -1% auto 0;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 50px 40px;
    font-size: 18px;
    background: rgba(41, 39, 39, 0.35);
    border-radius: 10px;
    box-shadow: 0 0.4px 0.4px rgba(128, 128, 128, 0.109),
      0 1px 1px rgba(128, 128, 128, 0.155),
      0 2.1px 2.1px rgba(128, 128, 128, 0.195),
      0 4.4px 4.4px rgba(128, 128, 128, 0.241),
      0 12px 12px rgba(128, 128, 128, 0.35);
    .title {
      margin: 0 0 24px 0;
      color: white;
      font-size: 36px;
      font-weight: 600;
    }
    .input {
      margin-bottom: 20px;
    }
    .btn {
      width: 100%;
      margin: 18px 0 9px 0;
      font-size: 20px;
      padding: 8px 20px;
      border-radius: 0;
      overflow: hidden;
      letter-spacing: 5px;
      border: 1px solid white;
      text-align: center;
      cursor: pointer;
    }
    .btn:hover {
      background: transparent;
      box-shadow: 0 0 20px 10px rgb(221, 72, 64, 0.5);
    }
    .btn-ghost {
      color: white;
      border-color: white;
      background: 0 0;
      transition: 0.3s;
    }
    // .btn-ghost:hover {
    //   color: white;
    //   background: white;
    // }
  }
}
::v-deep .el-input__inner {
  color: white;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  border-radius: 0;
  height: 60px;
  font-size: 20px;
  padding: 0;
}
.ulBox {
  background: transparent;
  text-align: center;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}

.ulBox li {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 12px;
  height: 28px;
  border-radius: 50%;
  display: inline-block;
  margin: 20px;
  box-shadow: 0px 4px 24px 3px var(--itemColor);
  background: var(--itemColor);
  animation: flash 1s linear infinite;
}

.ulBox li:before {
  content: "";
  position: absolute;
  background: red;
  width: 10px;
  height: 9px;
  border-radius: 3px;
  top: -4px;
  left: 1px;
}

.ulBox li:after {
  content: "";
  position: absolute;
  top: -14px;
  left: 9px;
  width: 52px;
  height: 18px;
  border-bottom: solid red 2px;
  border-radius: 150%;
}

.ulBox li:last-child:after {
  content: none;
}

@keyframes flash {
  0%,
  100% {
    background: var(--itemColor);
    box-shadow: 0px 4px 24px 3px var(--itemColor);
  }
  50% {
    background: var(--fromColor);
    box-shadow: 0px 4px 24px 3px var(--fromColor);
  }
}
</style>
